<template>
	<div
		id="login"
		class="container-sm flex flex-column align-center mx-auto py-60 px-85 background-white rounded shadow-lg"
		@keydown.enter="login"
	>
		<img
			class="mb-50"
			src="@/assets/terminal.svg"
			alt=""
		/>

		<h2 class="mb-20">Nýtt loyniorð</h2>

		<input
			type="password"
			v-model="form.password"
			class="w-100 mb-20 border p-20 color-grey rounded shadow"
			placeholder="Nýtt loyniorð"
			@blur="$v.form.password.$touch()"
			autofocus
		/>

		<input
			type="password"
			v-model="form.passwordConfirmation"
			class="w-100 mb-20 border p-20 color-grey rounded shadow"
			placeholder="Endurtak nýtt loyniorð"
			@blur="$v.form.passwordConfirmation.$touch()"
		/>

		<p
			v-if="errorMessage"
			class="mb-20 color-red"
		>
			{{ errorMessage }}
		</p>

		<a
			href="#"
			:class="{ disabled: this.$v.form.$invalid }"
			class="btn flex hover:background-blue-600"
			@click.prevent="login"
		>
			Áset nýtt loyniorð

			<div
				v-if="loading"
				class="flex justify-center ml-10"
			>
				<i class="fas fa-spinner fa-spin fa-lg"></i>
			</div>
		</a>
	</div>
</template>

<script>
import axios from 'axios';

const { required, email, sameAs } = require('vuelidate/lib/validators');

export default {
	name: 'ForgotPassword',

	data() {
		return {
			loading: false,
			errorMessage: '',
			form: {
				password: '',
				passwordConfirmation: '',
			},
		};
	},

	validations: {
		form: {
			password: {
				required,
			},
			passwordConfirmation: {
				required,
				sameAs: sameAs('password'),
			},
		},
	},

	methods: {
		async login() {
			if (this.loading) {
				return;
			}

			if (this.$v.form.$invalid) {
				return;
			}

			if (!this.$route.query.token) {
				return;
			}

			this.loading = true;

			axios
				.post(`/auth/reset-password/`, {
					token: this.$route.query.token,
					password: this.form.password,
					passwordConfirmation: this.form.passwordConfirmation,
				})
				.then(() => {
					this.$router.push({ name: 'Login' });
				})
				.catch((error) => {
					const status = error.response.status;

					if (status == 404) {
						this.errorMessage = 'Umbønin er ógyldig. Far aftur til "gloymt loyniorð".';
					}

					if (status == 400) {
						this.errorMessage = 'Umbønin er útgingin. Hon var galdandi í 10 minuttir. Far aftur til "gloymt loyniorð".';
					}

					if (status == 422) {
						this.errorMessage = 'Loyniorðini vóru ikki eins.';
					}
				})
				.finally(() => {
					this.loading = false;

					this.form.password = '';
					this.form.passwordConfirmation = '';
				});
		},
	},
};
</script>
